.main_acc_set_up12 {
    background-color: #1F1F1F;
    height: 100vh;
    width: 100%;
}

.app_container12 {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    justify-content: center;
    height: auto;
}

.form_container12 {
    background-color: #F9FAFF;
    padding: 20px;
    height: auto;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.form_container12 h1 {
    font-size: 20px;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 50px;
}

.form_container12 label {
    display: block;
    margin-bottom: 11px;
    color: #7E7E7E;
    font-size: 13px;
}

.form_container12 input {
    width: 100%;
    padding: 14px 12px;
    border: none;
    margin-bottom: 16px;
    box-sizing: border-box;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 6px;
}

.social_media_logs {
    margin-top: 30px;
    text-align: center;
    color: #7E7E7E;
}

.image_link_social {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;

}

.image_link_social button {
    border: none;

}

.image_link_social img {
    background-color: #F1F6FF;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    padding: 8px 30px;
    cursor: pointer;
}

.submit_form12 {
    margin: auto;
    margin-top: 23px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submit_form12 button {
    background-color: #4285F4;
    color: #fff;
    padding: 12px 22px 12px 22px;
    border: none;
    margin: auto;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    width: 80%;
    margin-top: 20px;
}

.submit_form12 p {
    text-align: center;
    margin-top: 20px;
}

@media screen and (max-width:1080px) {
    .app_container12 {
        padding: 15px;
    }

    .form_container12 {
        background-color: #F9FAFF;
        width: 100%;
        height: auto;
        margin-top: 30px;
        border-radius: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 400px;
    }
}