.main_sol_page_price {
    padding-top: 90px;
    padding-left: 90px;
    /* border: 1px solid red; */
}

.main_sol_page_price h1 {
    font-size: 50px;
    padding-right: 506px;
}

.main_sol_page_price p {
    padding-top: 20px;
    padding-right: 600px;
}

.btn2_price {
    display: flex;
    gap: 15px;
    margin-top: 35px;
}

.btn2_price button:first-child {
    background-color: var(--button-bg);
    border: none;
    font-weight: 500;
    border-radius: 5px;
    padding: 10px 25px;
    font-size: 15px;
    cursor: pointer;
}

.btn2_price button:last-child {
    border: 1px solid var(--button-bg);
    font-weight: 500;
    background-color: transparent;
    border-radius: 5px;
    padding: 10px 45px;
    font-size: 15px;
    cursor: pointer;
}

.main_prices_according {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: auto;
    margin-top: 50px;
}

.main_prices_according button {
    border: none;
    padding: 13px 50px;
    background-color: black;
    color: white;
    border-radius: 50px;
    font-size: 18px;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.main_prices_according button:hover {
    transition: .3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.sms_main_prices {
    margin-top: 90px;
    display: flex;
    gap: 25px;
}

.new_prices_sms {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #F4F4F4;
    padding: 15px 15px;
    text-align: center;
    transition: .3s ease-in-out;
    transform: scale(1);
}

.new_prices_sms:hover {
    transition: .3s ease-in-out;
    transform: scale(1.02);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.new_prices_sms h5 {
    font-size: 18px;
}

.new_prices_sms>p {
    color: #704646;
    font-size: 13px;
}

.new_prices_sms h3 {
    font-size: 38px;
    font-weight: lighter;
    margin-top: 22px;

    span {
        text-decoration: line-through;
        font-size: 15px;
    }
}

.new_prices_sms h6 {
    font-size: 13px;
    color: gray;
    margin-top: 20px;
    font-weight: lighter;
}

.new_prices_sms .id {
    background-color: #0764C1;
    color: white;
    padding: 4px;
    margin-top: 5px;
}

.new_prices_sms button {
    margin-top: 50px;
    padding: 10px 45px;
    background-color: #FAC213;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    transform: scale(1);
}

.new_prices_sms button:hover {
    transform: scale(1.1);
}

.features_sms {
    margin-top: 45px;
}

.features_sms h4 {
    font-size: 25px;
    font-weight: 400;
}

.features_sms p {
    font-size: 13px;
    font-weight: lighter;
    margin-top: 15px;
    border-bottom: 1px solid rgb(222, 222, 222);
}


/* payment setup .js */

.main_payment_setup {
    width: 100%;
    display: flex;
    background-color: #e6e6e6;
    height: 88vh;
    align-items: center;
    justify-content: center;
}

.sub_payment_form {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 30%;
    background-color: #F4F4F4;
    padding: 15px 20px;
    border-radius: 5px;
}

.sub_payment_form form {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.sub_payment_form input {
    padding: 6px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    outline: none;
}

.sub_payment_form select {
    padding: 6px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    outline: none;
}

.amount_pay_setup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-top: 10px;
}

.amount_pay_setup span {
    color: black;
    font-weight: 500;
}

.sub_payment_form button {
    padding: 6px;
    border: none;
    background-color: #0764C1;
    color: white;
    border-radius: 4px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
}

@media screen and (max-width:1080px) {
    .main_sol_page_price {
        display: none;
    }

    .main_prices_according {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .sms_main_prices {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

}

@media screen and (max-width:800px) {
    .sub_payment_form {
        width: 100%;
        padding: 15px 20px;
    }

}