.main_sol_page_verify {
  padding-top: 60px;
  padding-left: 90px;
}

.main_sol_page_verify h1 {
  font-size: 49px;
  /* padding-right: 400px; */
}

.main_sol_page_verify p {
  padding-right: 500px;
  font-size: 20px;
  position: relative;
  bottom: 25px;
}

.btn2_5 {
  display: flex;
  gap: 15px;
  margin-top: 28px;
}

.btn2_5 button:first-child {
  background-color: var(--button-bg);
  border: none;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 25px;
  font-size: 15px;
  cursor: pointer;
}

.btn2_5 button:last-child {
  border: 1px solid var(--button-bg);
  font-weight: 500;
  background-color: transparent;
  border-radius: 5px;
  padding: 10px 45px;
  font-size: 15px;
  cursor: pointer;
}

.main_sms2 {
  width: 90%;
  margin: auto;
  margin-top: 45px;
}

.sub_sms3 {
  background-color: #a6cece;
  /* background-image: url("/src/Assets/salim.svg"); */
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.sub_sms3 img {
  background-color: #a6cece;
  width: 40%;
  padding: 0;
}

.sub_sms3 img {
  width: 100%;
}

.main_sol_page9 {
  display: none;
}

.build_trus_t {
  width: 90%;
  display: flex;
  margin: auto;
  margin-top: 80px;
  text-align: center;
}

.build_in_text {
  margin-top: 80px;
  /* width: 50%; */
  padding-right: 100px;
  text-align: left;
}

.build_in_text h1 {
  margin-top: 80px;
}

.build_in_text p {
  margin-top: 20px;
  color: #666666;
  font-weight: 500;
}

.build_trus_t button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  text-decoration: underline;
  font-size: 18px;
  cursor: pointer;
  border: none;
  color: #5a74fa;
  background-color: transparent;
  text-align: center;
}

.build_trus_t img {
  width: 100%;
  margin: auto;
  margin-top: 20px;
}

.security_sms12 {
  display: flex;
  width: 90%;
  margin: auto;
  margin-top: 150px;
  justify-content: space-around;
}


.security_sms12 img {
  width: 35%;
  margin: auto;
  border-radius: 50%;
}

.main_sec_verify {
  display: flex;
  align-items: center;
}

.sub_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 30px;
  column-gap: 70px;
}

.sub_ver1 {
  width: 40%;
}

.sub_ver2 {
  width: 40%;
}

.sub_ver1 p,
.sub_ver2 p {
  width: 100px;
}

.sub_ver1 h1,
.sub_ver2 h1 {
  font-size: 24px;
}

.security_sms13 {
  display: flex;
  /* padding: 25px; */
  gap: 45px;
  background: rgba(166, 206, 206, 0.38);
  margin-top: 150px;
  padding: 45px;
  justify-content: center;
}

.security_sms13 p {
  padding-right: 150px;
}

.security_sms13 img {
  width: 35%;
  margin-left: 45px;
}

.para_seq13 img {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  width: 80px;
}

.para_seq13 button {
  margin-top: 30px;
  color: white;
  border: none;
  background-color: var(--previous-bg);
  border-radius: 3px;
}

.para_seq13 button a {
  margin-top: 20px;
  padding: 10px 35px;
  color: white;
  border: none;
  background-color: var(--previous-bg);
  border-radius: 3px;
}

.para_seq13 button a:hover {
  color: rgb(0, 0, 0);
  background-color: var(--button-bg);
}

.main_sec_verify {
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 90%;
  margin: auto;
}

.para_seq12 {
  width: 90%;
}

.sub_ver p {
  font-size: 23px;
}

.sub_ver span {
  color: #7c858b;
}

.main_achieve {
  width: 90%;
  display: flex;
  margin: auto;
  align-items: center;
  margin-top: 100px;
  padding: 15px;
}

.main_achieve img {
  width: 50%;
}

.main_achieve h1 {
  text-align: start;
}

.main_achieve p {
  text-align: start;
  margin-top: 20px;
}

.main_new_comm {
  margin-top: 70px;
}

.new_comm1,
.new_comm2,
.new_comm3 {
  display: flex;
  align-items: center;
  padding: 40px;
  gap: 25px;
}


.new_comm2 img {
  /* margin-top: 15px;
  width: 150px; */
  width: 8%;
}

.new_comm3 img {
  /* margin-top: 15px; */
  /* width: 150px; */
  width: 8%;
}

.new_comm1 h3 {
  font-size: 25px;
}

.new_comm2 h3 {
  font-size: 25px;
}

.new_comm3 h3 {
  font-size: 25px;
}

.new_comm1 img {
  width: 8%;
}


@media screen and (max-width: 1080px) {
  .main_sol_page_verify {
    display: none;
  }

  .main_sms2 {
    display: none;
  }

  .main_sol_page9 {
    display: block;
    width: 100%;
    padding: 10px;
  }

  .main_sol_page9 h1 {
    margin-top: 50px;
    font-size: 20px;
    width: 100%;
  }

  .main_sol_page9 p {
    display: block;
    width: 100%;
    font-size: 13px;
    margin-top: 20px;
  }

  .main_sol_page9 img {
    width: 100%;
    margin-top: 50px;
    border-radius: 20px;
  }

  .btn_verify {
    display: flex;
    /* border: 1px solid red; */
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 29px;
  }

  .btn_verify button:first-child {
    background-color: var(--previous-bg);
    border: none;
    font-weight: 500;
    color: white;
    border-radius: 3px;
    padding: 10px 30px;
    font-size: 15px;
    cursor: pointer;
  }

  .btn_verify button:first-child:hover {
    background-color: var(--button-bg);
    color: black;
  }

  .btn_verify button:last-child {
    border: 1px solid var(--button-bg);
    font-weight: 500;
    background-color: transparent;
    border-radius: 3px;
    padding: 10px 50px;
    font-size: 15px;
    cursor: pointer;
  }

  .build_trus_t {
    width: 90%;
    margin: auto;
    margin-top: 45px;
    flex-direction: column;
  }

  .build_in_text {
    padding: 8px;
  }

  .build_in_text h1 {
    margin-top: 80px;
    color: #050038;
    font-size: 23px;
  }

  .build_in_text p {
    margin: auto;
    font-size: 13px;
    width: 100%;
    margin-top: 20px;
  }

  .build_trus_t button {
    display: flex;
    align-items: center;
    margin-top: 20px;
    text-decoration: underline;
    font-size: 15px;
    border: none;
    color: #5a74fa;
    background-color: transparent;
    text-align: center;
  }

  .build_trus_t img {
    width: 100%;
    margin: auto;
    margin-top: 20px;
    text-align: center;
  }

  .security_sms12 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-top: 150px;
    justify-content: space-around;
  }

  .para_seq12 {
    margin-top: 25px;
    padding: 0;
    width: 100%;
  }

  .para_seq12 h1 {
    font-size: 20px;
  }

  .para_seq12 p {
    font-size: 13px;
    margin-top: 15px;
    color: #666666;
    font-weight: 500;
  }

  .security_sms12 img {
    margin-top: 50px;
    width: 100%;
  }

  .security_sms13 {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    background-color: #ddecec;
    padding: 10px 20px;
    margin-top: 150px;
    justify-content: space-around;
  }

  .sub_sms3 {
    display: none;
  }

  .security_sms13 img {
    margin-top: 50px;
    width: 100%;
    overflow: hidden;
  }

  .para_seq13 img {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    width: 60px;
  }

  .sub_container {
    display: flex;
    flex-direction: column;
  }

  .sub_ver1,
  .sub_ver2 {
    width: 100%;
  }

  .sub_ver1 p,
  .sub_ver2 p {
    width: 50px;
  }

  .para_seq13 button {
    margin-top: 30px;
    color: white;
    border: none;
    background-color: var(--previous-bg);
    border-radius: 3px;
  }

  .para_seq13 button a {
    margin-top: 20px;
    padding: 10px 35px;
    color: white;
    border: none;
    background-color: var(--previous-bg);
    border-radius: 3px;
  }

  .para_seq13 button a:hover {
    color: rgb(0, 0, 0);
    background-color: var(--button-bg);
  }

  .sub_ver p {
    font-size: 25px;
  }

  .sub_ver h1 {
    font-size: 23px;
  }

  .sub_ver span {
    color: #7c858b;
    font-size: 15px;
  }

  .main_achieve {
    width: 90%;
    display: flex;
    flex-direction: column-reverse;
    margin: auto;
    align-items: center;
    margin-top: 100px;
    padding: 15px;
  }

  .main_achieve img {
    width: 100%;
  }

  .new_para_ver {
    width: 100%;
    margin-top: 25px;
  }

  .new_para_ver h1 {
    text-align: left;
    font-size: 20px;
  }

  .new_para_ver p {
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    color: #666666;
    margin-top: 20px;
  }

  .main_new_comm>div {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .new_comm1 p,
  .new_comm2 p,
  .new_comm3 p {
    width: 110px;
  }

  .new_comm1 img,
  .new_comm2 img,
  .new_comm3 img {
    width: 20%;
  }
}